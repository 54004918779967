<template>
  <div>
    <el-config-provider :locale="elLocale">
      <router-view v-if="$route.meta.isShowLayout" />
      <div v-else>
        <div class="content-header">
          <Header />
          <NavBar />
        </div>
        <div class="content-wrapper">
          <router-view />
          <LayoutFooter />
        </div>
      </div>
      <RealNameModal />
  </el-config-provider>
  </div>
</template>

<script setup>
import NavBar from "./views/dongnanyaView/layout/NavBar.vue";
import LayoutFooter from "./views/dongnanyaView/layout/LayoutFooter.vue";
import RealNameModal from "./components/RealNameModal.vue";
import Header from "./views/dongnanyaView/layout/Header.vue";
import { computed } from 'vue';
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import { useI18n } from 'vue-i18n';
 
// 全局更改element默认语言
const { locale } = useI18n();
const elLocale = computed(() => {
 return locale.value === 'en' ? en : zhCn
})

</script>

<style>
  /* 全局样式 */
  body,
  html {
    margin: 0;
    padding: 0;
  }
  .content-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
  .content-wrapper {
    width: 100%;
    min-height: 100vh;
    margin-top: 124px;
  }

  /* 大屏幕上的最大宽度 */
  /* @media (min-width: 1200px) {
    .content-wrapper {
      max-width: 1350px; 
    }
  } */

  /* 小屏幕上的最大宽度百分比 */
  /* @media (max-width: 1199px) {
    .content-wrapper {
      max-width: 95%; 
    }
  } */
  .ant-menu-horizontal {
    border-bottom: none !important;
  }
</style>
