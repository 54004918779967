<template>
  <div :class="$style.headerWrap">
    <div :class="$style.left">
      <img :class="$style.icon" :src="zuhaoguanjiaImg" alt="" @click="onClickImg"/>
      <a-menu
        class="cuMenu"
        :selectedKeys="store?.state?.navSelectedKeys"
        mode="horizontal"
        :items="items"
        :disabledOverflow="true"
        @click="handleClick"
      />
    </div>
    <div :class="$style.right">
      <div :class="$style.cdkBtn" @click="onCdkC">{{ $t('header.cdkBtn') }}</div>
    </div>
  </div>
</template>

<script setup>
  import { useStore } from "vuex";
  import { ref, h, useCssModule } from "vue";
  import zuhaoguanjiaImg from "@assets/zuhaoguanjia.png";
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  const store = useStore();
  const styles = useCssModule();
  const router = useRouter();
  const { t } = useI18n();

  const items = ref([
    {
      key: "/dny/cdk_use",
      label: h("div", { class: styles.itemLabel }, t('header.cdkExchange')),
    },
    {
      key: "/dny/rent_active",
      label: h("div", { class: styles.itemLabel }, t('header.renting')),
    },
    {
      key: "/dny/rent_history",
      label: h("div", { class: styles.itemLabel }, t('header.rentHistory')),
    },
    {
      key: "/dny/yongjiuquanyi",
      label: h("div", { class: styles.itemLabel }, t('header.permanentCard')),
    },
  ]);

  const onClickImg = () => {
    router.push('/dny/cdk_use');
  }

  const handleClick = (e) => {
    router.push(e.key);
  }

  const onCdkC = () => {
    router.push('/dny/cdk_use');
  }
</script>

<style module>
  .headerWrap {
    height: 80px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 0 230px;
  }
  .icon {
    width: 185px;
    margin-right: 38px;
    cursor: pointer;
  }
  .left {
    display: flex;
    align-items: center;
  }
  .itemLabel {
    font-size: 16px;
  }
  .right {
    display: flex;
    align-items: center;
  }
  .input {
    width: 192px;
    height: 36px;
    font-size: 15px;
    border-radius: 36px;
  }
  .searchIcon {
    font-size: 18px;
    color: #D3D3D3;
  }
  .cdkBtn {
    margin-left: 20px;
    width: 107px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    border-radius: 36px;
    cursor: pointer;
    background: linear-gradient(rgba(147, 160, 255), rgba(38, 62, 247))
  }
</style>
<style>
  .cuMenu .ant-menu-item {
    padding-inline: 16px !important;
    line-height: 46px !important;
  }
  .cuMenu {
    line-height: 46px !important;
  }
</style>