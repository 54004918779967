<template>
  <div :class="$style.wrap">
    <h1 :class="$style.title">{{ $t('cdkExchange.title') }}</h1>
    <div :class="$style.search">
      <a-input-search
        v-model:value="cdkey"
        :placeholder="$t('cdkExchange.placeholder')"
        :enter-button="$t('cdkExchange.submitButton')"
        size="large"
        @search="onSearch"
        :loading="isLoading"
      />
    </div>

    <a-modal v-model:open="isOpen" :title="$t('cdkExchange.modalTitle')" :footer="null" :width="400">
      <div :class="$style.modalWrap">
        <a-button size="large" @click="onUseOld">{{ $t('cdkExchange.renew') }}</a-button>
        <a-button size="large" @click="onUseGenNew">{{ $t('cdkExchange.exchange') }}</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "@/axios";
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const cdkey = ref("");
const isOpen = ref(false);
const router = useRouter();
const isLoading = ref(false);

// 续期
const onUseOld = async() => {
  const res = await axios.post("web/all_cdk_use_old/", { cdkey: cdkey.value}).catch(() => {});
  if (res?.data?.status === 10000) {
    router.push("/dny/rent_active");
    isOpen.value = false;
  }
}

// 兑换
const onUseGenNew = async() => {
  const res = await axios.post("web/all_cdk_use_gen_new/", { cdkey: cdkey.value}).catch(() => {});
  if (res?.data?.status === 10000) {
    router.push("/dny/rent_active");
    isOpen.value = false;
  }
}

const onSearch = async(searchValue) => {
  if(!searchValue) return message.warning(t('cdkExchange.enterCdkeyWarning'));
  isLoading.value = true;
  const res = await axios.post("web/all_cdk_use/", { cdkey: searchValue}).catch(() => {});
  isLoading.value = false;
  if (res?.data?.status === 10000) {
    // is_exits：是否存在，如果存在要弹窗让用户选择 续期还是兑换
    if (res?.data?.data?.is_exits) {
      isOpen.value = true;
    } else {
      router.push("/dny/rent_active");
    }
  }
};
</script>

<style module>
  .wrap {
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .title {
    margin-bottom: 10px;
    font-size: 42px;
    margin-bottom: 10px;
  }
  .search {
    width: 655px;
  }
  .describe {
    margin-bottom: 200px;
  }
  .describeItem {
    font-size: 15px;
    font-weight: 700;
    margin: 15px 0;
  }
  .describeA {
    color: #337ab7;
    text-decoration: none;
  }
  .modalWrap {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
</style>
