export default {
    header: {
      cdkExchange: 'CDK兑换',
      renting: '正在租用',
      rentHistory: '租用记录',
      permanentCard: '我的永久权益卡',
      cdkBtn: 'CDK兑换',
      login: '登录',
      register: '注册',
      logout: '注销'
    },
    footer: {
      address: '地址：莆田市',
      copyright: '版权所有',
      company: '莆田市租号管家网络科技有限公司',
      icp: '闽ICP备2024030079号-1',
      valueAddedServiceLicense: '增值业务经营许可证：闽B2-20240249'
    },
    rentHistory: {
      title: '正在租用记录',
      gameName: '游戏名称',
      account: '账户',
      copyAccount: '复制账号',
      password: '密码',
      copyPassword: '复制密码',
      remark: '备注',
      endTime: '结束时间',
      extendTime: '延期/加时长',
      actions: '操作',
      switchAccount: '切换账号',
      getVerificationCode: '获取验证码',
      cdkeyOrder: 'cdkey/订单号',
      verificationTitle: '验证码如下：',
      copyVerification: '复制验证码',
      selectAccountTitle: '选择租用账号',
      selectAccountPlaceholder: '请选择账号',
      selectAccountWarning: '请选择一个账号',
      confirm: '确认',
      cancel: '取消',
      violationWarning: '禁止开挂、转租、恶意占用等违反用户协议的行为，违者封号！'
    },
    cdkExchange: {
      title: 'CDK兑换',
      placeholder: '兑换CDKEY',
      submitButton: '提交',
      modalTitle: '选择操作',
      renew: '续期',
      exchange: '兑换',
      enterCdkeyWarning: '请输入CDKEY'
    },
    rentHistoryRecords: {
      title: '租用历史记录',
      gameName: '游戏名称',
      startTime: '开始时间',
      endTime: '结束时间',
      account: '账户',
      cdkey: 'CDKEY',
      remark: '备注'
    },
    permanentCard: {
      title: '永久权益卡',
      gameName: '游戏名称',
      createTime: '创建时间',
      cdkeyOrder: 'CDKEY/订单号',
      actions: '操作',
      rent: '租号',
      viewDetails: '查看详情',
      selectAccountTitle: '选择租用账号',
      violationWarning: '禁止开挂、转租、恶意占用等违反用户协议的行为，违者封号！',
      selectAccountPlaceholder: '请选择账号',
      selectAccountWarning: '请选择一个账号',
      confirm: '确认',
      cancel: '取消'
    },
    login: {
        title: '用户登录',
        username: '用户名',
        password: '密码',
        captcha: '图片验证码',
        remember: '记住密码',
        forgot: '忘记密码？',
        login: '登录',
        or: '或者',
        register: '注册用户!',
        usernameRequired: '请输入用户名!',
        passwordRequired: '请输入密码!',
        captchaRequired: '请输入图片验证码!',
        captchaRefreshFailed: '获取验证码失败'
      },
      register: {
        title: '注册',
        email: '电子邮件',
        password: '密码',
        confirmPassword: '确认密码',
        inviteCode: '邀请码（可选）',
        captcha: '图片验证码',
        smsCode: '验证码',
        agreeTerms: '同意相关条款和条件',
        submit: '注册',
        login: '已有账号？现在登录！',
        emailRequired: '请输入电子邮件!',
        passwordRequired: '请输入密码!',
        confirmPasswordRequired: '请确认您的密码!',
        captchaRequired: '请输入图片验证码!',
        smsCodeRequired: '请输入验证码!',
        agreeTermsRequired: '请同意相关条款和条件!',
        passwordMismatch: '密码不一致!',
        smsCodeButton: '获取验证码',
        smsCodeWaiting: '{countdown}秒后重新获取',
        captchaRefreshFailed: '获取验证码失败',
        captchaOrEmailMissing: '请先输入图片验证码和电子邮件!'
      }
  };
  