import { createI18n } from 'vue-i18n';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import customEN from './en'  // 自定义英语包
import customZH from './zh'  // 自定义中文包
 
 
const messages = {
    en: {
        ...customEN,
        ...en
    },
    zh: {
        ...customZH ,
        ...zhCn
    }
}


// 获取浏览器语言
const preferredLang = localStorage.getItem('preferred_lang');
const lang = preferredLang || (navigator.language || navigator.browserLanguage).toLowerCase();
 
const i18n = createI18n({
 messages,
 locale: 'en',
 allowComposition: true,
 legacy: true, // 如果要支持compositionAPI，此项必须设置为false;
 globalInjection: true // 全局注册$t方法
});
 
export default i18n;