<template>
  <div class="rent-history-container">
    <h4 class="table-title">{{ $t('rentHistory.title') }}</h4>
    <el-table :data="activeRentList" style="width: 80%">
      <el-table-column prop="gname" :label="$t('rentHistory.gameName')" />
      <el-table-column prop="ename" :label="$t('rentHistory.gameName')" />
      <el-table-column prop="saccount" :label="$t('rentHistory.account')">
        <template #default="{ row }">
          <el-button size="" type="primary" @click="copyToClipboard(row.saccount)">{{ $t('rentHistory.copyAccount') }}</el-button>
          <br />
          {{ row.saccount }}
        </template>
      </el-table-column>
      <el-table-column prop="spassword" :label="$t('rentHistory.password')">
        <template #default="{ row }">
          <el-button size="" type="primary" @click="copyToClipboard(row.spassword)">{{ $t('rentHistory.copyPassword') }}</el-button>
          <br />
          {{ row.spassword }}
        </template>
      </el-table-column>
      <el-table-column prop="remark" :label="$t('rentHistory.remark')" />
      <el-table-column prop="etime" :label="$t('rentHistory.endTime')">
        <template #default="{ row }">
          <el-button size="" type="primary" @click="extendTime(row)">{{ $t('rentHistory.extendTime') }}</el-button>
          <br />
          {{ row.etime }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('rentHistory.actions')">
        <template #default="{ row }">
          <el-button size="" type="primary" @click="openRentqiehuanModal(row)">{{ $t('rentHistory.switchAccount') }}</el-button>
          <br />
          <el-button size="" type="danger" @click="getVerificationCode(row)">{{ $t('rentHistory.getVerificationCode') }}</el-button>
        </template>
      </el-table-column>
      <el-table-column :label="$t('rentHistory.cdkeyOrder')">
        <template #default="{ row }">
          {{ row.cdkey || row.order }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handlePageChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="totalItems"
    />
    <el-dialog v-model="verificationDialogVisible" :title="$t('rentHistory.verificationTitle')" width="30%">
      <span>{{ verificationCode }}</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="confirmVerification">{{ $t('rentHistory.copyVerification') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="rentDialogVisible" :title="$t('rentHistory.selectAccountTitle')" width="30%">
      <p style="color: red; font-weight: bold; font-size: 10px;">
        &nbsp;{{ $t('rentHistory.violationWarning') }}
      </p>
      <el-select v-model="selectedAccount" :placeholder="$t('rentHistory.selectAccountPlaceholder')" style="width: 80%">
        <el-option
          v-for="account in accountOptions"
          :key="account.id"
          :label="account.name"
          :value="account.id"
          :disabled="!account.is_kongxian"
        />
      </el-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="rentDialogVisible = false">{{ $t('rentHistory.cancel') }}</el-button>
          <el-button type="primary" @click="confirmRent">{{ $t('rentHistory.confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from '@/axios';
import { message } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    const activeRentList = ref([]);
    const totalItems = ref(0);
    const currentPage = ref(1);
    const pageSize = 10;
    const verificationDialogVisible = ref(false);
    const verificationCode = ref('');

    const rentDialogVisible = ref(false);
    const accountOptions = ref([]);
    const selectedAccount = ref(null);
    const selectedRow = ref(null);

    const fetchRentList = async (page = 1) => {
      const response = await axios.post('/web/rent_no/', {
        limit: pageSize,
        page: page,
      });
      if (response.data.status === 10000) {
        activeRentList.value = response.data.data.items;
        totalItems.value = response.data.data.total;
      }
    };

    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchRentList(page);
    };

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        message.success(t('rentHistory.copySuccess'));
      });
    };

    const extendTime = async (row) => {
      try {
        const response = await axios.post('/web/account/yanqiv2/', {
          yqid: row.id,
        });
        if (response.data.status === 10000) {
          row.etime = response.data.data.end_time; // 更新结束时间
          // 调用弹窗展示到期时间
        }
      } catch (error) {
        message.error(t('rentHistory.extendFail'));
      }
    };

    const getVerificationCode = async (row) => {
      try {
        const response = await axios.post('/web/account/yzma/', {
          yzid: row.id,
        });
        if (response.data.status === 10000) {
          verificationCode.value = response.data.data.verification_code; // 替换为实际验证码内容
          verificationDialogVisible.value = true;
        }
      } catch (error) {
        message.error(t('rentHistory.getVerificationFail'));
      }
    };

    const confirmVerification = () => {
      navigator.clipboard.writeText(verificationCode.value).then(() => {
          verificationDialogVisible.value = false;
          message.success(t('rentHistory.copyVerificationSuccess'));
      }).catch(() => {
          message.error(t('rentHistory.copyVerificationFail'));
      });
    };

    const openRentqiehuanModal = async (row) => {
      selectedRow.value = row;
      const response = await axios.post('/web/rentno/qiehuan_list/', {
        uid: row.id
      });
      if (response.data.status === 10000) {
        accountOptions.value = response.data.data;
        rentDialogVisible.value = true;
      }
    };

    const confirmRent = async () => {
      if (!selectedAccount.value) {
        message.warning(t('rentHistory.selectAccountWarning'));
        return;
      }
      const response = await axios.post('/web/rentno/qiehuan/', {
        uid: selectedRow.value.id,
        account: selectedAccount.value
      });
      if (response.data.status === 10000) {
        rentDialogVisible.value = false;
        fetchRentList(currentPage.value);
      }
    };

    onMounted(() => fetchRentList(currentPage.value));

    return {
      activeRentList,
      totalItems,
      currentPage,
      handlePageChange,
      copyToClipboard,
      extendTime,
      verificationDialogVisible,
      verificationCode,
      getVerificationCode,
      confirmVerification,
      rentDialogVisible,
      accountOptions,
      openRentqiehuanModal,
      confirmRent,
      selectedAccount,
    };
  },
};
</script>

<style scoped>
.rent-history-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-title {
  text-align: center;
  margin-bottom: 20px;
}
</style>
