export default {
    header: {
      cdkExchange: 'CDK Exchange',
      renting: 'Currently Renting',
      rentHistory: 'Rent History',
      permanentCard: 'My Permanent Rights Card',
      cdkBtn: 'CDK Exchange',
      login: 'Login',
      register: 'Register',
      logout: 'Logout'
    },
    footer: {
      address: 'Address: Putian City',
      copyright: 'All rights reserved',
      company: 'Putian City Account Rental Manager Network Technology Co., Ltd.',
      icp: 'Min ICP No. 2024030079-1',
      valueAddedServiceLicense: 'Value-added Telecommunications Business License: Min B2-20240249'
    }
    ,
    rentHistory: {
      title: 'Active Rental Records',
      gameName: 'Game Name',
      account: 'Account',
      copyAccount: 'Copy Account',
      password: 'Password',
      copyPassword: 'Copy Password',
      remark: 'Remark',
      endTime: 'End Time',
      extendTime: 'Extend/Add Time',
      actions: 'Actions',
      switchAccount: 'Switch Account',
      getVerificationCode: 'Get Verification Code',
      cdkeyOrder: 'CDKEY/Order Number',
      verificationTitle: 'Verification Code:',
      copyVerification: 'Copy Verification Code',
      selectAccountTitle: 'Select Rental Account',
      selectAccountPlaceholder: 'Please select an account',
      selectAccountWarning: 'Please select an account',
      confirm: 'Confirm',
      cancel: 'Cancel',
      violationWarning: 'Prohibited behaviors such as cheating, subletting, and malicious occupation, violators will be banned!'
    },
    cdkExchange: {
      title: 'CDK Exchange',
      placeholder: 'Enter CDKEY',
      submitButton: 'Submit',
      modalTitle: 'Select Action',
      renew: 'Renew',
      exchange: 'Exchange',
      enterCdkeyWarning: 'Please enter CDKEY'
    },
    rentHistoryRecords: {
      title: 'Rental History Records',
      gameName: 'Game Name',
      startTime: 'Start Time',
      endTime: 'End Time',
      account: 'Account',
      cdkey: 'CDKEY',
      remark: 'Remark'
    },
    permanentCard: {
      title: 'Permanent Rights Card',
      gameName: 'Game Name',
      createTime: 'Creation Time',
      cdkeyOrder: 'CDKEY/Order Number',
      actions: 'Actions',
      rent: 'Rent Account',
      viewDetails: 'View Details',
      selectAccountTitle: 'Select Rental Account',
      violationWarning: 'Prohibited behaviors such as cheating, subletting, and malicious occupation, violators will be banned!',
      selectAccountPlaceholder: 'Please select an account',
      selectAccountWarning: 'Please select an account',
      confirm: 'Confirm',
      cancel: 'Cancel'
    },
    login: {
        title: 'User Login',
        username: 'Username',
        password: 'Password',
        captcha: 'Captcha',
        remember: 'Remember me',
        forgot: 'Forgot password?',
        login: 'Log in',
        or: 'Or',
        register: 'Register now!',
        usernameRequired: 'Please input your username!',
        passwordRequired: 'Please input your password!',
        captchaRequired: 'Please input the captcha!',
        captchaRefreshFailed: 'Failed to refresh captcha'
      },
      register: {
        title: 'Register',
        email: 'Email',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        inviteCode: 'Invite Code (optional)',
        captcha: 'Captcha',
        smsCode: 'SMS Code',
        agreeTerms: 'Agree to the terms and conditions',
        submit: 'Register',
        login: 'Already have an account? Log in now!',
        emailRequired: 'Please input your email!',
        passwordRequired: 'Please input your password!',
        confirmPasswordRequired: 'Please confirm your password!',
        captchaRequired: 'Please input the captcha!',
        smsCodeRequired: 'Please input the SMS code!',
        agreeTermsRequired: 'Please agree to the terms and conditions!',
        passwordMismatch: 'Passwords do not match!',
        smsCodeButton: 'Get SMS Code',
        smsCodeWaiting: '{countdown} seconds to resend',
        captchaRefreshFailed: 'Failed to refresh captcha',
        captchaOrEmailMissing: 'Please input captcha and email first!'
      }
  };
  