// store.js
import { createStore } from 'vuex';
import axios from '../axios';

export default createStore({
  state: {
    user: localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info'))  : {},
    isAuthenticated: !!localStorage.getItem('access_token'),
    isRealOpen: false,
    navSelectedKeys: ['/'],
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    getUser: state => state.user,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('user_info', JSON.stringify(user));
    },
    SET_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_ISREALOPEN(state, isRealOpen) {
      state.isRealOpen = isRealOpen;
    },
    SET_SELECTEDKEYS(state, keys) {
      state.navSelectedKeys = keys;
    },
  },
  actions: {
    async login({ commit }, user) {
      commit('SET_USER', user);
      commit('SET_AUTHENTICATED', true);
    },
    async logout({ commit }) {
      try {
        const refreshToken = localStorage.getItem('refresh_token');
        await axios.post('userauth/logout/', { refresh_token: refreshToken });
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        commit('SET_USER', null);
        commit('SET_AUTHENTICATED', false);
      } catch (error) {
        console.error('Logout failed:', error);
        throw error;
      }
    }
  },
  modules: {
  }
});
