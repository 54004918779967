<template>
  <div class="rent-history-container">
    <h4 class="table-title">{{ $t('rentHistoryRecords.title') }}</h4>
    <el-table :data="historyRentList" style="width: 80%">
      <el-table-column prop="gname" :label="$t('rentHistoryRecords.gameName')" />
      <el-table-column prop="ename" :label="$t('rentHistoryRecords.gameName')" />
      <el-table-column prop="stime" :label="$t('rentHistoryRecords.startTime')" />
      <el-table-column prop="etime" :label="$t('rentHistoryRecords.endTime')" />
      <el-table-column prop="saccount" :label="$t('rentHistoryRecords.account')" />
      <el-table-column prop="cdkey" :label="$t('rentHistoryRecords.cdkey')" />
      <el-table-column prop="langtime_remark" :label="$t('rentHistoryRecords.remark')" />
    </el-table>
    <el-pagination
      @current-change="handlePageChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="totalItems"
    />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from '@/axios';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    const historyRentList = ref([]);
    const totalItems = ref(0);
    const currentPage = ref(1);
    const pageSize = 10;

    const fetchRentList = async (page = 1) => {
      const response = await axios.post('/web/history_rent_no/', {
        limit: pageSize,
        page: page
      });
      if (response.data.status === 10000) {
        historyRentList.value = response.data.data.items;
        totalItems.value = response.data.data.total;
      }
    };

    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchRentList(page);
    };

    onMounted(() => fetchRentList(currentPage.value));

    return {
      historyRentList,
      totalItems,
      currentPage,
      handlePageChange,
      t
    };
  }
};
</script>

<style scoped>
.rent-history-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-title {
  text-align: center;
  margin-bottom: 20px;
}
</style>
