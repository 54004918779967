<template>
  <div class="rent-history-container">
    <h4 class="table-title">{{ $t('permanentCard.title') }}</h4>
    <el-table :data="rightsList" style="width: 80%">
      <el-table-column prop="gname" :label="$t('permanentCard.gameName')" />
      <el-table-column prop="ename" :label="$t('permanentCard.gameName')" />
      <el-table-column prop="stime" :label="$t('permanentCard.createTime')" />
      <el-table-column :label="$t('permanentCard.cdkeyOrder')">
        <template #default="{ row }">
          {{ row.cdkey || row.order }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('permanentCard.actions')">
        <template #default="{ row }">
          <el-button
            v-if="!row.is_exits_rent"
            size=""
            type="primary"
            @click="openRentModal(row)"
          >
            {{ $t('permanentCard.rent') }}
          </el-button>
          <el-button
            v-else
            size=""
            type="primary"
            @click="viewRentDetails(row)"
          >
            {{ $t('permanentCard.viewDetails') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handlePageChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="totalItems"
    />
    <el-dialog v-model="rentDialogVisible" :title="$t('permanentCard.selectAccountTitle')" width="30%">
      <p style="color: red; font-weight: bold; font-size: 10px;">
        &nbsp;{{ $t('permanentCard.violationWarning') }}
      </p>
      <el-select v-model="selectedAccount" :placeholder="$t('permanentCard.selectAccountPlaceholder')" style="width: 80%">
        <el-option
          v-for="account in accountOptions"
          :key="account.id"
          :label="account.name"
          :value="account.id"
          :disabled="!account.is_kongxian"
        />
      </el-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="rentDialogVisible = false">{{ $t('permanentCard.cancel') }}</el-button>
          <el-button type="primary" @click="confirmRent">{{ $t('permanentCard.confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from '@/axios';
import { message } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    const rightsList = ref([]);
    const totalItems = ref(0);
    const currentPage = ref(1);
    const pageSize = 10;
    const rentDialogVisible = ref(false);
    const accountOptions = ref([]);
    const selectedAccount = ref(null);
    const selectedRow = ref(null);

    const fetchRightsList = async (page = 1) => {
      const offset = (page - 1) * pageSize;
      const response = await axios.post('/web/yong_jiu_rent_quan_yi/', {
        limit: pageSize,
        page: page
      });
      if (response.data.status === 10000) {
        rightsList.value = response.data.data.items;
        totalItems.value = response.data.data.total;
      }
    };

    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchRightsList(page);
    };

    const openRentModal = async (row) => {
      selectedRow.value = row;
      const response = await axios.post('/web/rentno/zhhao_account_rental_log/', {
        uid: row.id
      });
      if (response.data.status === 10000) {
        accountOptions.value = response.data.data;
        rentDialogVisible.value = true;
      }
    };

    const viewRentDetails = (row) => {
      // 跳转到租号详情页面的逻辑
      window.location.href = `/dny/rent_active`;
    };

    const confirmRent = async () => {
      if (!selectedAccount.value) {
        message.warning(t('permanentCard.selectAccountWarning'));
        return;
      }
      const response = await axios.post('/web/rentno/chioce_account/', {
        uid: selectedRow.value.id,
        account: selectedAccount.value
      });
      if (response.data.status === 10000) {
        rentDialogVisible.value = false;
        window.location.href = '/dny/rent_active';
      }
    };

    onMounted(() => fetchRightsList(currentPage.value));

    return {
      rightsList,
      totalItems,
      currentPage,
      handlePageChange,
      rentDialogVisible,
      accountOptions,
      openRentModal,
      viewRentDetails,
      confirmRent,
      selectedAccount,
      t
    };
  }
};
</script>

<style scoped>
.rent-history-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-title {
  text-align: center;
  margin-bottom: 20px;
}
</style>
